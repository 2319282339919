const BaseService = require('./BaseService');
const ApiUrls = require('../helpers/ApiUrls');
const Buffer = require('buffer').Buffer;
const AppSettings = require('../helpers/AppSettings');
const client = require('../node_modules/axios/dist/axios.js');
var nacl_factory = require("../node_modules/js-nacl/lib/nacl_factory.js");
var nacl;
nacl_factory.instantiate(function (nacl_temp) {
    nacl = nacl_temp;
})

class PaymentService extends BaseService {

    _payment(payload) {
        return this.api.post(ApiUrls.payment(), payload);
    }

    async _apayment(payload, failurecb) {
        try {
            const response = await this.api.apost(ApiUrls.payment(), payload, null, null, failurecb);
            // console.log('_apayment response data', response);
            return response;

        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;

        }
    }

    async _paymentFlow(payload, onFailure, onBeforeRedirect) {

        try {
            // Call await payment api
            const response = await this._apayment(payload, onFailure);
            // console.log("****************************** window is");
            // console.log(typeof window);

            if (onBeforeRedirect) {
                onBeforeRedirect(response);
            }
            if (payload["pmt_channel"] === "EPAY" && payload["source"] !== "checkout-embed" && payload["response_type"] !== "redirect_url_only") {
                    // console.log("pmt_channel : ",payload["pmt_channel"])
                    var paymentForm = document.getElementById('megapayForm');

                    var domain = response.data.additional_data.domain;
                    paymentForm.elements["timeStamp"].value = response.data.additional_data.timeStamp;
                    paymentForm.elements['invoiceNo'].value = response.data.additional_data.invoiceNo;
                    paymentForm.elements['merTrxId'].value = response.data.additional_data.merTrxId;
                    paymentForm.elements['merId'].value = response.data.additional_data.merId;
                    paymentForm.elements['amount'].value = response.data.additional_data.amount;
                    paymentForm.elements["merchantToken"].value = response.data.additional_data.merchantToken;
                    paymentForm.elements["callBackUrl"].value = response.data.additional_data.callBackUrl
                    paymentForm.elements["notiUrl"].value = response.data.additional_data.notiUrl
                    paymentForm.elements["payType"].value = response.data.additional_data.payType
                    paymentForm.elements["bankCode"].value = response.data.additional_data.bankCode
                    paymentForm.elements["buyerEmail"].value = response.data.additional_data.buyerEmail
                    paymentForm.elements["buyerPhone"].value = response.data.additional_data.receiverPhone
                    paymentForm.elements["goodsNm"].value = response.data.additional_data.goodsNm
                    paymentForm.elements["buyerFirstNm"].value = response.data.additional_data.buyerFirstNm
                    paymentForm.elements["buyerLastNm"].value = response.data.additional_data.buyerLastNm
                    paymentForm.elements["reqDomain"].value = response.data.additional_data.reqDomain
                    paymentForm.elements["description"].value = response.data.additional_data.description
                    paymentForm.elements["userIP"].value = response.data.additional_data.userIP
                    paymentForm.elements["userLanguage"].value = response.data.additional_data.userLanguage
                    paymentForm.elements["windowColor"].value = response.data.additional_data.windowColor
                    paymentForm.elements["windowType"].value = "1"
                    if(payload['pmt_method'] === "EPAY_VIRTUAL_ACCOUNT"){
                        paymentForm.elements["vaStartDt"].value = response.data.additional_data.vaStartDt
                        paymentForm.elements["vaEndDt"].value = response.data.additional_data.vaEndDt
                    }

                    // console.log("EPAY Merchant Token:",response.data.additional_data.merchantToken)

                    openPayment(1, domain);
                }
                
            
            if (payload["pmt_channel"] === "ASIAPAY") {
                // console.log("pmt_channel : ",payload["pmt_channel"])
                var paymentForm = document.getElementById('asiaPayForm');

                paymentForm.elements['orderRef'].value = response.data.additional_data.orderRef;
                paymentForm.elements['merchantId'].value = response.data.additional_data.merchantId;
				paymentForm.elements['amount'].value = response.data.additional_data.amount;
                paymentForm.elements["secureHash"].value = response.data.additional_data.secureHash;
                paymentForm.elements["successUrl"].value = response.data.additional_data.successUrl;
                paymentForm.elements["failUrl"].value = response.data.additional_data.failUrl;
                paymentForm.elements["cancelUrl"].value = response.data.additional_data.cancelUrl
				paymentForm.elements["payMethod"].value = response.data.additional_data.payMethod
                paymentForm.elements["currCode"].value = response.data.additional_data.currCode
                paymentForm.action = response.data.additional_data.postingUrl;  

                // console.log(paymentForm)
				// console.log(response.data.additional_data)
                $("#asiaPayForm").submit();
            }

            if (payload["pmt_channel"] === "PAYSOLUTIONS" && payload["source"] !== "checkout-embed" && payload["response_type"] !== "redirect_url_only" && payload['pmt_method'] !== "PS_INSTALLMENT") {
                console.log("pmt_channel : ",payload["pmt_channel"])
                var paymentForm = document.getElementById('paySolutionForm');

                paymentForm.elements['refno'].value = response.data.additional_data.refno;
                paymentForm.elements['merchantid'].value = response.data.additional_data.merchantid;
				paymentForm.elements['customeremail'].value = response.data.additional_data.customeremail;
                paymentForm.elements["cc"].value = response.data.additional_data.cc;
                paymentForm.elements["productdetail"].value = response.data.additional_data.productdetail;
                paymentForm.elements["total"].value = response.data.additional_data.total;
                paymentForm.elements["channel"].value = response.data.additional_data.channel
				paymentForm.elements["returnurl"].value = response.data.additional_data.returnurl

                console.log(paymentForm)
				console.log(response.data.additional_data)
                $("#paySolutionForm").submit();
            }

            if (payload["pmt_channel"] === "GBPRIMEPAY" && payload["source"] !== "checkout-embed" && payload["response_type"] !== "redirect_url_only" && response.data.additional_data != null) {
                console.log("pmt_channel : ",payload["pmt_channel"])
                if(payload["pmt_method"] === "GBPRIMEPAY_RABBIT_LINEPAY_WALLET" ||payload["pmt_method"] === "GBPRIMEPAY_TRUEMONEY_WALLET" ||payload["pmt_method"] === "GBPRIMEPAY_SHOPEEPAY_WALLET"){
                    var paymentForm = document.getElementById('GBPPayForm');
                    paymentForm.elements['amount'].value = response.data.additional_data.amount;
                    paymentForm.elements['backgroundUrl'].value = response.data.additional_data.backgroundUrl;
                    paymentForm.elements['checksum'].value = response.data.additional_data.checksum;
                    paymentForm.elements["customerAddress"].value = response.data.additional_data.customerAddress;
                    paymentForm.elements["customerEmail"].value = response.data.additional_data.customerEmail;
                    paymentForm.elements["customerName"].value = response.data.additional_data.customerName;
                    paymentForm.elements["customerTelephone"].value = response.data.additional_data.customerTelephone;
                    paymentForm.elements["detail"].value = response.data.additional_data.detail
                    paymentForm.elements["publicKey"].value = response.data.additional_data.publicKey
                    paymentForm.elements["referenceNo"].value = response.data.additional_data.referenceNo
                    paymentForm.elements["responseUrl"].value = response.data.additional_data.responseUrl
                    paymentForm.action = response.data.additional_data.url;               
                    // console.log(paymentForm)
                    // console.log(response.data.additional_data)
                    $("#GBPPayForm").submit();
                }
                if(payload["pmt_method"] === "GBPRIMEPAY_KPLUS_BANK" || payload["pmt_method"] === "GBPRIMEPAY_KRUNGSRI_BANK" ||payload["pmt_method"] === "GBPRIMEPAY_BBL_BANK" ||payload["pmt_method"] === "GBPRIMEPAY_KRUNGTHAI_BANK" || payload["pmt_method"] === "GBPRIMEPAY_SCBEASY_BANK" ){
                    var paymentFormNetBanking = document.getElementById('GBPPayFormNetBanking');
                    paymentFormNetBanking.elements['amount'].value = response.data.additional_data.amount;
                    paymentFormNetBanking.elements['backgroundUrl'].value = response.data.additional_data.backgroundUrl;
                    paymentFormNetBanking.elements['bankCode'].value = response.data.additional_data.bankCode;
                    paymentFormNetBanking.elements['checksum'].value = response.data.additional_data.checksum;
                    paymentFormNetBanking.elements["customerAddress"].value = response.data.additional_data.customerAddress;
                    paymentFormNetBanking.elements["customerEmail"].value = response.data.additional_data.customerEmail;
                    paymentFormNetBanking.elements["customerName"].value = response.data.additional_data.customerName;
                    paymentFormNetBanking.elements["customerTelephone"].value = response.data.additional_data.customerTelephone;
                    paymentFormNetBanking.elements["detail"].value = response.data.additional_data.detail;
                    paymentFormNetBanking.elements["publicKey"].value = response.data.additional_data.publicKey;
                    paymentFormNetBanking.elements["referenceNo"].value = response.data.additional_data.referenceNo;
                    paymentFormNetBanking.elements["responseUrl"].value = response.data.additional_data.responseUrl;
                    paymentFormNetBanking.action = response.data.additional_data.url;               
                    // console.log(paymentFormNetBanking)
                    // console.log(response.data.additional_data)
                    $("#GBPPayFormNetBanking").submit();
                }
                if(payload["pmt_method"] === "GBPRIMEPAY_CREDIT_CARD"){
                    var paymentFormCard = document.getElementById('GBPPayFormCard');
                    paymentFormCard.elements['gbpReferenceNo'].value = response.data.additional_data.gbpReferenceNo;
                    paymentFormCard.elements['publicKey'].value = response.data.additional_data.publicKey;
                    paymentFormCard.action = response.data.additional_data.url;             
                    // console.log("paymentFormCard :", paymentFormCard)
                    // console.log(response.data.additional_data)
                    $("#GBPPayFormCard").submit();
                }

                if(payload["pmt_method"] === "GBPRIMEPAY_DEBIT_CARD"){
                    var paymentFormAtmCard = document.getElementById('GBPPayFormAtmCard');
                    paymentFormAtmCard.elements['gbpReferenceNo'].value = response.data.additional_data.gbpReferenceNo;
                    paymentFormAtmCard.elements['publicKey'].value = response.data.additional_data.publicKey;
                    paymentFormAtmCard.action = response.data.additional_data.url;             
                    // console.log("paymentFormAtmCard :", paymentFormAtmCard)
                    // console.log(response.data.additional_data)
                    $("#GBPPayFormAtmCard").submit();
                }
                if(payload["pmt_method"] === "GBPRIMEPAY_INSTALLMENT"){
                    var paymentFormInstallment = document.getElementById('GBPPayFormInstallment');
                    paymentFormInstallment.elements['amount'].value = response.data.additional_data.amount;
                    paymentFormInstallment.elements['backgroundUrl'].value = response.data.additional_data.backgroundUrl;
                    paymentFormInstallment.elements['bankCode'].value = response.data.additional_data.bankCode;
                    paymentFormInstallment.elements['checksum'].value = response.data.additional_data.checksum;
                    paymentFormInstallment.elements["customerAddress"].value = response.data.additional_data.customerAddress;
                    paymentFormInstallment.elements["customerEmail"].value = response.data.additional_data.customerEmail;
                    paymentFormInstallment.elements["customerName"].value = response.data.additional_data.customerName;
                    paymentFormInstallment.elements["customerTelephone"].value = response.data.additional_data.customerTelephone;
                    paymentFormInstallment.elements["detail"].value = response.data.additional_data.detail;
                    paymentFormInstallment.elements["publicKey"].value = response.data.additional_data.publicKey;
                    paymentFormInstallment.elements["referenceNo"].value = response.data.additional_data.referenceNo;
                    paymentFormInstallment.elements["responseUrl"].value = response.data.additional_data.responseUrl;
                    paymentFormInstallment.elements["term"].value = response.data.additional_data.term;
                    paymentFormInstallment.action = response.data.additional_data.url;             
                    // console.log(paymentFormInstallment)
                    // console.log(response.data.additional_data)
                    $("#GBPPayFormInstallment").submit();
                }
                if(payload["pmt_method"] === "GBPRIMEPAY_ATOME"){
                    var paymentForm = document.getElementById('GBPPayFormAtome');
                    paymentForm.elements['amount'].value = response.data.additional_data.amount;
                    paymentForm.elements['backgroundUrl'].value = response.data.additional_data.backgroundUrl;
                    paymentForm.elements['checksum'].value = response.data.additional_data.checksum;
                    paymentForm.elements["customerAddress"].value = response.data.additional_data.customerAddress;
                    paymentForm.elements["customerEmail"].value = response.data.additional_data.customerEmail;
                    paymentForm.elements["customerName"].value = response.data.additional_data.customerName;
                    paymentForm.elements["customerTelephone"].value = response.data.additional_data.customerTelephone;
                    paymentForm.elements["detail"].value = response.data.additional_data.detail
                    paymentForm.elements["publicKey"].value = response.data.additional_data.publicKey
                    paymentForm.elements["referenceNo"].value = response.data.additional_data.referenceNo
                    paymentForm.elements["responseUrl"].value = response.data.additional_data.responseUrl
                    paymentForm.action = response.data.additional_data.url;               
                    // console.log(paymentForm)
                    // console.log(response.data.additional_data)
                    $("#GBPPayFormAtome").submit();
                }   
            }


            if (payload["pmt_channel"] === "IPAY88") {
                if(payload["pmt_method"] === "IPAY88_RABBIT_LINE_PAY" || payload["pmt_method"] === "IPAY88_PROMPT_PAY" ||payload["pmt_method"] === "IPAY88_TRUE_MONEY" ||payload["pmt_method"] === "IPAY88_SHOPEEPAY_JUMPAPP" || payload["pmt_method"] === "IPAY88_KBANK" || payload["pmt_method"] === "IPAY88_SCB" ||payload["pmt_method"] === "IPAY88_BAY" ||payload["pmt_method"] === "IPAY88_BBL"|| payload["pmt_method"] === "IPAY88_CREDIT_CARD"){
                    var iPay88Form = document.getElementById('IPay88Form');
                    iPay88Form.elements['MerchantCode'].value = response.data.additional_data.MerchantCode;
                    iPay88Form.elements['PaymentId'].value = response.data.additional_data.PaymentId;
                    iPay88Form.elements['RefNo'].value = response.data.additional_data.RefNo;
                    iPay88Form.elements['Amount'].value = response.data.additional_data.Amount;
                    iPay88Form.elements['Currency'].value = response.data.additional_data.Currency;
                    iPay88Form.elements['ProdDesc'].value = response.data.additional_data.ProdDesc;
                    iPay88Form.elements['UserName'].value = response.data.additional_data.UserName;
                    iPay88Form.elements["UserEmail"].value = response.data.additional_data.UserEmail;
                    iPay88Form.elements["UserContact"].value = response.data.additional_data.UserContact;
                    iPay88Form.elements["Remark"].value = "";
                    iPay88Form.elements["Lang"].value = "ISO-8859-1";
                    iPay88Form.elements["SignatureType"].value = response.data.additional_data.SignatureType
                    iPay88Form.elements["Signature"].value = response.data.additional_data.Signature
                    iPay88Form.elements["ResponseURL"].value = response.data.additional_data.ResponseURL
                    iPay88Form.elements["BackendURL"].value = response.data.additional_data.BackendURL
                    iPay88Form.action = response.data.additional_data.PostingURL;              
                    // console.log(iPay88Form)
                    // console.log(response.data.additional_data)
                    $("#IPay88Form").submit();
                }
                
                if(payload["pmt_method"] === "IPAY88_LINKAJA" || payload["pmt_method"] === "IPAY88_OVO" ||payload["pmt_method"] === "IPAY88_SHOPEEPAY" || payload["pmt_method"] === "IPAY88_DANA" || payload["pmt_method"] === "IPAY88_SHOPEEPAY_QR" || payload["pmt_method"] === "IPAY88_NOBUBANK_QR" || payload["pmt_method"] === "IPAY88_AKULAKU" || payload["pmt_method"] === "IPAY88_PERMATA_VA" || payload["pmt_method"] === "IPAY88_MAYBANK_VA" || payload["pmt_method"] === "IPAY88_MANDIRI_ATM" || payload["pmt_method"] === "IPAY88_GPN"){
                    var iPay88IndoForm = document.getElementById('IPay88IndoForm');
                    iPay88IndoForm.elements['CheckoutID'].value = response.data.additional_data.CheckoutID;
                    iPay88IndoForm.elements['Signature'].value = response.data.additional_data.Signature;
                    iPay88IndoForm.action = response.data.additional_data.PostingURL;              
                    console.log(iPay88IndoForm)
                    console.log(response.data.additional_data)
                    $("#IPay88IndoForm").submit();
                }
            }

            // if (payload["pmt_channel"] === "MOMOPAY"){
            //     if (window.innerWidth > 768){
            //         window.location.href = response.data.redirect_url;
            //     }else {
            //         window.location.href = response.data.deep_link;
            //     }
            // }
            if(payload["override_auto_redirect"]){
                return response; 
            }

            if (response.data && response.data.redirect_url) {
                if (payload["pmt_method"] != "CHAIPORT_COD" && payload["source"]==="checkout-embed" && !payload["token_params"]){
                    if (payload["pmt_channel"] === "MOMOPAY" && window.innerWidth < 768){
                        this.OpenPaymentPopup(response.data.deep_link, 'web', 1050, 800, payload, response.data.order_ref)
                    }else{
                        this.OpenPaymentPopup(response.data.redirect_url, 'web', 1050, 800, payload, response.data.order_ref)
                    }
                }else if(payload["source"]==="checkout-embed" && payload["token_params"]){
                    window.parent.location.href = response.data.redirect_url;
                }else{
                    if (payload["pmt_channel"] === "MOMOPAY" && window.innerWidth < 768){
                        if(payload["custom_params"] && payload["custom_params"].momoPayMiniAppOrderGroupId && payload["custom_params"].momoPayMiniAppOrderGroupId.momo_miniapp){
                            window.location.href = response.data.deep_link;
                        }
                        else{
                            window.location.href = response.data.redirect_url;
                        }
                    }
                    else {
                        window.location.href = response.data.redirect_url;
                    }
                }
            }

            if (response.data && response.data.orderurl) {
                window.location.href = response.data.orderurl;
            }

            if (response.data && response.data.payUrl) {
                window.location.href = response.data.payUrl;
            }

            return response;

        } catch (error) {
            throw error;
        }

    }

    OpenPaymentPopup(paymentUrl, title, paymentScreenWidth, paymentScreenHeight, payload, order_ref) {
        var left = (screen.width - paymentScreenWidth) / 2;
        var top = (screen.height - paymentScreenHeight) / 3;

        var newWindow = window.open(paymentUrl, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' + paymentScreenWidth + ', height=' + paymentScreenHeight + ', top=' + top + ', left=' + left);
        
        
        async function initiateGetPaymentStatus(){
            var res = await getPaymentStatus()
            

            if((res["status"] === "Success")){
                window.parent.location.href = res["redirect_url"];
                clearInterval(timer);
                newWindow.close();      
            }
            else if(res["status"] === "Failed"){
                $("#PaymentStatusFail").modal("show")
                clearInterval(timer);
                newWindow.close();
            }
             
        }   
        initiateGetPaymentStatus()

        var timer = window.setInterval(async function(){
            await initiateGetPaymentStatus();
        }, 10000);
        var timer1 = window.setInterval(async function(){
            await checkChild();
        }, 500);

        async function getPaymentStatus(){
            const res = await fetch(common.api_domain + '/api/capturePayment', {
                method: 'POST',
                body: JSON.stringify({
                    "environment": payload["environment"],
                    "pmt_channel": payload["pmt_channel"],
                    "order_ref": order_ref,
                    "merchant_order_ref": payload["merchant_order_id"],
                    "source": "checkout-embed"
                }),
                headers: {
                    "Content-Type": "application/json",
                },
            });
            var resJson = await res.json();
            console.log("response received", resJson)
            return resJson
        }
        async function checkChild() {
            if(newWindow){
                if (newWindow.closed) {
                    var resp = await getPaymentStatus();
                    if(resp["status"] !== "Success"){
                        $("#PaymentStatusFail").modal("show")
                        clearInterval(timer);
                        clearInterval(timer1);
                    }
                    else{
                        window.parent.location.href = resp["redirect_url"];
                        clearInterval(timer);      
                        clearInterval(timer1);
                    }     
                }
            }
        }
            
        if(!newWindow || newWindow.closed || typeof newWindow.closed=='undefined'){ 
            $("#PaymentPopupBlock").modal("show")
        }
    }

    payment(payload, onFailure, onBeforeRedirect) {
        console.log('Payload req js', payload);
        console.log('Payload[card_details] req js', payload["card_details"]);

        if (payload["card_details"] != null) {
            // this.api.aget(ApiUrls.getTokenizationKey()+"?environment="+payload["environment"], null, null, null, onFailure).then((response) =>{
            //     console.log('_getTokenizationKey response data', response.data.api_key);
            //     var key = response.data.api_key
    
                this._agetCardToken(payload, onFailure).then((response)=>{
                    this._paymentFlow(response, onFailure, onBeforeRedirect);
                }); 
            //})
        }else{
            this._paymentFlow(payload, onFailure, onBeforeRedirect);
        }
    }

    getPaymentHistory(paymentId) {
        return this.api.get(ApiUrls.paymentHistory(paymentId));
    }

    tokenizeCard(payload, onFailure, onSuccess) {
        this.api.aget(ApiUrls.getTokenizationKey()+"?environment="+payload["environment"], null, null, null, onFailure).then((response) =>{
            var key = response.data.api_key
            this._agetCardToken(key, payload, onFailure).then((response)=>{
                if (onSuccess) {
                    onSuccess(response)
                }
            }); 
        })
    }

    async _apostCreditCards(tokenKey, environment, card_details, failurecb,successcb) {
        try {

            // Convert the public key from hex to binary format
            var publicKey = nacl.from_hex('58bfb06252b5f9270750c67c92c6fbceed5a6615a25c4952591e2d0da465e13d');
            

            // The data to be encrypted
            const cardNumber = card_details.card_number;
            const serviceCode = card_details.service_code;
            
            // Generate a random message to encrypt
            const cardNumberNew =  Buffer.from(cardNumber, 'utf-8');
            const serviceCodeNew = Buffer.from(serviceCode, 'utf-8');
        

            // Encrypt the card number and CVV using the public key and nonce
            var encryptedCardNumberData = nacl.crypto_box_seal(cardNumberNew, publicKey);
            var encryptedDataServiceCode = nacl.crypto_box_seal(serviceCodeNew,publicKey);
            

            // Convert the encrypted card number and CVV to hex format
            var encryptedDataCardNumberHex = nacl.to_hex(encryptedCardNumberData);
            var encryptedDataServiceCodeHex = nacl.to_hex(encryptedDataServiceCode);

            // const response = await this.api.aget(ApiUrls.getSavedCards(number,otp), null, null, successcb, failurecb);
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.postCreditCards()}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                     "Authorization": "Bearer "+(tokenKey || AppSettings.jwtToken),
                },
                data: {
                    "card_number": encryptedDataCardNumberHex,
                    "cardholder_name": card_details.cardholder_name,
                    "service_code": encryptedDataServiceCodeHex,
                    "expiration_month": card_details.expiration_month,
                    "expiration_year": card_details.expiration_year,
                    "card_type": card_details.card_type,
                    'environment': environment
                },
                method:'POST'
            };
            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
             
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
        
    }
    postCreditCards(tokenKey, environment,card_details, onFailure,onSuccess) {
        this._apostCreditCards(tokenKey, environment,card_details, onFailure,onSuccess);
    }

    async _agetCardToken(payload, failurecb) {
        try {
            // let config = {
            //     url: `${AppSettings.tokenizationUrl}/${ApiUrls.getCardToken()}`+'?api_key='+key,
            //     headers: {
            //         'content-type': 'application/json'
            //     },
            //     method:'POST'
            // };
            // var card = {
            //     "card_number": payload.card_details.card_number,
            //     "card_type": payload.card_details.card_type,
            //     "cardholder_name": payload.card_details.cardholder_name,
            //     "service_code": payload.card_details.service_code,
            //     "expiration_month": payload.card_details.expiration_month,
            //     "expiration_year": payload.card_details.expiration_year,
            // }
            // config.data = {"card":card};
            // const responseJson = await client.request(config);
            // console.log('_getCardToken response data', responseJson.data.data);
            // if (responseJson.data != null) {
            //     var tokenData = responseJson.data.data.attributes
            // }
            var tokenData = payload.card_details;
            payload["token_params"] = {
                "token": tokenData.card_token,
                "partial_card_number": tokenData.card_number,
                "expiry_month": tokenData.expiration_month,
                "expiry_year": tokenData.expiration_year,
                "type": payload.card_details.card_type,
                "save_card":payload.card_details.save_card
            }

            delete payload.card_details;
            return payload;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    async _agenerateOTP(number, failurecb) {
        try {
            const response = await this.api.apost(ApiUrls.generateOTP(number), null, null, null, failurecb);
            return response;

        } catch (error) {
            throw error;

        }
    }

    generateOTP(number, onFailure) {
        this._agenerateOTP(number, onFailure);
    }


    async _agetSavedCards(number,otp,environment, failurecb,successcb) {
        try {
            // const response = await this.api.aget(ApiUrls.getSavedCards(number,otp), null, null, successcb, failurecb);
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.getSavedCards(number,otp,environment)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey
                },
                method:'GET'
            };
            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    getSavedCards(number,otp,environment, onFailure,onSuccess) {
        this._agetSavedCards(number,otp,environment, onFailure,onSuccess);
    }

    async _agetSavedCardsFromJwt(number,tokenKey,environment, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.getSavedCardsFromJwt(number, environment)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                    "Authorization": "Bearer "+tokenKey,
                },
                method:'GET'
            };
            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    getSavedCardsFromJwt(number,tokenKey,environment, onFailure,onSuccess) {
        this._agetSavedCardsFromJwt(number,tokenKey,environment, onFailure,onSuccess);
    }

    async _agetTxnRouter(tokenKey,environment, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.getTxnRouter(AppSettings.secretKey, environment)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                    "Authorization": "Bearer "+tokenKey,
                },
                method:'GET'
            };
            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    getTxnRouter(tokenKey,environment, onFailure,onSuccess) {
        this._agetTxnRouter(tokenKey,environment, onFailure,onSuccess);
    }


    async _apostCaptureTxn(tokenKey,order_ref, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.postCaptureTxn(order_ref)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                     "Authorization": "Bearer "+(tokenKey || AppSettings.jwtToken),
                },
                
                method:'POST'
            };
            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    postCaptureTxn(tokenKey,environment, onFailure,onSuccess) {
        this._apostCaptureTxn(tokenKey,environment, onFailure,onSuccess);
    }


    async _apostCardForCustomer(tokenKey,customer_id, card_details, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.postCardForCustomer(customer_id)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                     "Authorization": "Bearer "+(tokenKey || AppSettings.jwtToken),
                },
                data: {
                    "token": card_details.token,
                    "partial_card_number": card_details.partialCardNumber,
                    "expiry_month": card_details.expMonth,
                    "expiry_year": card_details.expYear,
                    "type": card_details.cardType
                },
                
                method:'POST'
            };

            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    postCardForCustomer(tokenKey,customer_id, card_details, onFailure,onSuccess) {
        this._apostCardForCustomer(tokenKey,customer_id, card_details, onFailure,onSuccess);
    }


    async _adeleteCardForCustomer(tokenKey,customer_id, card_details, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.deleteCardForCustomer(customer_id)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                     "Authorization": "Bearer "+(tokenKey || AppSettings.jwtToken),
                },
                data: {
                    "token": card_details.token,
                },
                
                method:'DELETE'
            };

            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    deleteCardForCustomer(tokenKey,customer_id, card_details, onFailure,onSuccess) {
        this._adeleteCardForCustomer(tokenKey,customer_id, card_details, onFailure,onSuccess);
    }

    async _agetCardForCustomer(tokenKey,customer_id, failurecb,successcb) {
        try {
            let config = {
                url: `${AppSettings.baseApiUrl}/${ApiUrls.getCardForCustomer(customer_id)}`,
                headers: {
                    'content-type': 'application/json',
                    "X-Chaipay-Client-Key":AppSettings.secretKey,
                     "Authorization": "Bearer "+(tokenKey || AppSettings.jwtToken),
                },
                
                method:'GET'
            };

            const responseJson = await client.request(config);
            if (successcb) {
                successcb(responseJson)
            }
            return responseJson;
        } catch (error) {
            if (failurecb) {
                failurecb(error)
            }
            throw error;
        }
    }

    getCardForCustomer(tokenKey,customer_id, onFailure,onSuccess) {
        this._agetCardForCustomer(tokenKey,customer_id, onFailure,onSuccess);
    }


    async _agetUserAddress(number, failurecb,successcb) {
        try {
            const response = await this.api.aget(ApiUrls.getUserAddress(number), null, null, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }
    }

    getUserAddress(number, onFailure,onSuccess) {
        this._agetUserAddress(number, onFailure,onSuccess);
    }
    

    async _acreateUserAddress(number,payload, failurecb,successcb) {
        try {
            const response = await this.api.apost(ApiUrls.createUserAddress(number), payload, null, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }
    }

    createUserAddress(number,payload, onFailure,onSuccess) {
        this._acreateUserAddress(number,payload, onFailure,onSuccess);
    }

    async _aupdateUserAddress(address_ref,payload, failurecb,successcb) {
        try {
            const response = await this.api.aput(ApiUrls.updateUserAddress(address_ref), payload, null, successcb, failurecb);
            return response;

        } catch (error) {
            throw error;

        }
    }

    updateUserAddress(address_ref,payload, onFailure,onSuccess) {
        this._aupdateUserAddress(address_ref,payload, onFailure,onSuccess);
    }
}

module.exports = PaymentService;